import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { DeviceOrderingService } from 'src/app/services/device-ordering/device-ordering.service';
import { DeviceVO, PatientVO } from 'src/app/services/device-ordering/value-objects/order.vo';

@Component({
  selector: 'app-new-device-modal',
  templateUrl: './new-device-modal.component.html',
  styleUrls: ['./new-device-modal.component.scss']
})
export class NewDeviceModalComponent implements OnInit {
  addressForm: FormGroup

  hasUnitNumber = false;

  public selectedManufacturer = ''
  public selectedDevice: DeviceVO;

  states = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'American Samoa', abbreviation: 'AS' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'District Of Columbia', abbreviation: 'DC' },
    { name: 'Federated States Of Micronesia', abbreviation: 'FM' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Guam', abbreviation: 'GU' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Marshall Islands', abbreviation: 'MH' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Northern Mariana Islands', abbreviation: 'MP' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Palau', abbreviation: 'PW' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Puerto Rico', abbreviation: 'PR' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virgin Islands', abbreviation: 'VI' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PatientVO,
    private dialogRef: MatDialogRef<NewDeviceModalComponent>,
    public deviceService: DeviceOrderingService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {

    this.addressForm = this.fb.group({
      firstName: [this.data && this.data.firstName ? this.data.firstName : null, Validators.required],
      lastName: [this.data && this.data.lastName ? this.data.lastName : null, Validators.required],
      address: [this.data && this.data.address && this.data.address.street ? this.data.address.street : null, Validators.required],
      address2: null,
      city: [this.data && this.data.address && this.data.address.city ? this.data.address.city : null, Validators.required],
      state: [this.data && this.data.address && this.data.address.state ? this.data.address.state : null, Validators.required],
      postalCode: [this.data && this.data.address && this.data.address.postalCode ? this.data.address.postalCode : null, Validators.compose([
        Validators.required, Validators.minLength(5), Validators.maxLength(5)])
      ],
      manufacturer: [''],
      type: [''],
    });

    this.deviceService
      .getAllDevices()
      .pipe(take(1))
      .subscribe();
  }

  handleManufacturerSelected() {
    this.deviceService
      .getDevicesByManufacturer(this.selectedManufacturer)
      .pipe(take(1))
      .subscribe();
  }

  orderDevice() {
    const device = this.addressForm.getRawValue().type;
    this.dialogRef.close(device);
  }

  onSubmit() {
    this.dialogRef.close(this.addressForm.value);
  }
}
