import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ShellComponent } from './shell/shell.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CameraComponent } from '../camera/camera.component';
import { DeviceSelectComponent } from '../settings/device-select.component';
import { ParticipantsComponent } from '../participants/participants.component';
import { SettingsComponent } from '../settings/settings.component';
import { VideoChatComponent } from '../video-chat/video-chat.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
// for making API calls
import { HttpClientModule } from '@angular/common/http';
import { NewDeviceModalComponent } from './new-device-modal/new-device-modal.component';
import { AreYouSureModalComponent } from './are-you-sure-modal/are-you-sure-modal.component';
import { StripeFormComponent } from './stripe-form/stripe-form.component';
import { SimplePaginatorComponent } from './components/simple-paginator/simple-paginator.component';
import { PhoneFormaterDirective } from './directives/phone-formater.directive';
import { SendFaxDialogComponent } from './dialogs/send-fax-dialog/send-fax-dialog.component';
import { TimeTrackingModalComponent } from './time-tracking-modal/time-tracking-modal.component';
import { ClinicalTimeSaveFormComponent } from './components/clinical-time-save-form/clinical-time-save-form.component';
import { VitalMeasurementComponent } from './templates/vital-measurement/vital-measurement.component';

// shared module here is designed to import all of our shared UI
// features where we can just inject this module directly into other cpomponents.

const components = [
  CameraComponent,
  DeviceSelectComponent,
  ParticipantsComponent,
  SettingsComponent,
  ShellComponent,
  VideoChatComponent,
  NewDeviceModalComponent,
  AreYouSureModalComponent,
  StripeFormComponent,
  SimplePaginatorComponent,
  SendFaxDialogComponent,
  TimeTrackingModalComponent,
  ClinicalTimeSaveFormComponent,
  VitalMeasurementComponent,
];

const modules = [
  CommonModule,
  DragDropModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatToolbarModule,
  MatRadioModule,
  MatIconModule,
  MatTabsModule,
  LayoutModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatDialogModule,
  MatListModule,
  MatMenuModule,
  MatIconModule,
  MatCardModule,
  MatFormFieldModule,
  MatSelectModule,
  MatInputModule,
  MatPaginatorModule,
  MatExpansionModule,
  MatSortModule,
  MatTableModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSnackBarModule,
  MatGridListModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatBadgeModule,
  FlexLayoutModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  MatTooltipModule,
  HttpClientModule,
  // JwksValidationHandler,
];
const directives = [PhoneFormaterDirective];

@NgModule({
  declarations: [...components, ...directives],
  imports: [...modules, MatInputModule, MatButtonModule, MatSelectModule, MatRadioModule, MatCardModule, ReactiveFormsModule],
  exports: [...components, ...directives, ...modules],
})
export class SharedModule {}
