import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';

export interface CreateUserDto {
    practice: string, fName: string, lName: string, uid?: string, clientId?: string,
    practiceAdmin?: boolean, provider?: boolean, patient?: boolean, pwHash?: string,
    npi?: string
}

@Injectable({ providedIn: 'root' })
export class UserFactory {

    constructor(private db: AngularFirestore) { }

    async createNewClient(emailAddress: string, data: CreateUserDto) {

        // create a new client object
        const newClient = { client_name: data.practice, client_npi: data.npi };
        // get a reference to the clients collection
        const clientsRef: DocumentReference = await this.db.collection('clients').add(newClient);
        // get the client id
        const clientId = clientsRef.id;

        try {
            await clientsRef.set({
                client_id: clientId,
                ccm_clinical: { active: false, rate: 0 },
                rpm_clinical: { active: false, rate: 0 },
                software_license: { active: true, rate: 40 },
            }, { merge: true });
        } catch (e) {
            console.log('Error trying to set client data', e);
        }

        try {
            await clientsRef.collection('emr_data').doc(clientId).set({
                emr_access: false,
                emr_login_id: 'N/A',
                emr_name: 'none'
            });
        } catch (e) { 
            console.log('Error trying to set EMR data', e);
        }

        try {
            const details = {
                active: true,
                name: `${data.fName} ${data.lName}`,
                primary_email: emailAddress,
                primary_phone: '',
                role: 'none',
                uid: clientId
            };
            await clientsRef.collection('client_contacts').doc(clientId).set(details);
        } catch (e) { 
            console.log('Error trying to set client contacts data', e);
        }

        return clientId;
    }

    async createUserAccount(emailAddress: string, data: CreateUserDto) {

        const activeUser = {
            client_access: [data.clientId],
            client_accounts: [data.clientId],
            client_responsible: data.practice,
            client_responsible_id: data.clientId,
            dob: new Date(),
            email: emailAddress,
            pw_hash: data.pwHash,
            firstName: data.fName,
            lastName: data.lName,
            phone: '',
            profileImageURL: 'https://firebasestorage.googleapis.com/v0/b/welby-dev.appspot.com/o/back_end%2Fimage.png?alt=media&token=804868e9-8403-4572-9169-80500066d88c',
            ssn_id: 'n/a',
            timeStamp: new Date(),
            user_id: data.uid,
            roles: {
                isPatient: data.patient,
                isClient: data.provider,
                isAdmin: false,
                isPracticeAdmin: data.practiceAdmin,
            },
        };

        const userDetails = await this.db.collection('users').doc(data.uid).set(activeUser);

        return userDetails;

    }
}