import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';

import * as version from '../assets/version.json';
import { AreYouSureModalComponent } from './shared/are-you-sure-modal/are-you-sure-modal.component';

export class Group {
  level = 0;
  parent: Group;
  expanded = true;
  totalCounts = 0;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'welby-web';

  constructor(private db: AngularFirestore, private dialog: MatDialog) {}

  ngOnInit(): void {}

  async ngAfterViewInit() {
    // add a 2 second wait
    await new Promise((resolve) => setTimeout(resolve, 2000));
    const webAppVersionSnap = await this.db.collection('_version_').doc('web-app').get().toPromise();
    if (webAppVersionSnap.exists) {
      const major = webAppVersionSnap.data()['major'];
      const minor = webAppVersionSnap.data()['minor'];
      const patch = webAppVersionSnap.data()['patch'];
      if (major !== version.major || minor !== version.minor || patch !== version.patch) {
        const modal = this.dialog.open(AreYouSureModalComponent, {
          data: {
            title: `New version available! ${major}.${minor}.${patch}`,
            body: 'There is a new version of the web app available. Please refresh the page.',
            hideCancelBtn: true,
            confirmButton: 'Refresh',
          },
        });
        // refresh the page after closed
        modal.afterClosed().subscribe(() => {
          window.location.reload();
        });
      }
    }
  }
}
