import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/firestore';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { InitialDataModel } from './models/initial-data-model.service';

export interface Type {
  value: string;
  viewValue: string;
}

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(
    public dialog: MatDialog,
    private db: AngularFirestore,
    private dataModel: InitialDataModel,
    private breakpointObserver: BreakpointObserver
  ) {
    // this.getUsers();
    this.getClients();
  }

  dashboard_panel = 'alerts';

  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  private tabSource = new BehaviorSubject<number>(0);
  currentTab = this.tabSource.asObservable();

  private patientSearchSource = new BehaviorSubject<string>('test');
  currentSearchTerm = this.patientSearchSource.asObservable();

  tasks = {};
  clients = {};

  phoneTypes = {
    cell: { value: 'cell', viewValue: 'Cell Phone', icon: 'stay_current_portrait' },
    home: { value: 'home', viewValue: 'Home Phone', icon: 'phone' },
    email: { value: 'email', viewValue: 'Electronic Mail', icon: 'phone' },
  };

  diagnoses = {
    Diabetes: { diagnosis: 'Diabetes', code_system: 'Snomed', code_value: 'E10.65' },
    Hypertension: { diagnosis: 'Hypertension', code_system: 'Snomed', code_value: '38341003' },
    None: { diagnosis: 'N/A', code_system: 'N/A', code_value: 'N/A' },
  };

  plans = {
    medicare: {
      viewValue: 'Medicare',
      logo: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.joVPffnuyPhXvGycLjpRLAHaEq%26pid%3DApi&f=1',
    },
    bcbs: {
      viewValue: 'Blue Cross',
      logo: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.joVPffnuyPhXvGycLjpRLAHaEq%26pid%3DApi&f=1',
    },
    aetna: {
      viewValue: 'Aetna',
      logo: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.joVPffnuyPhXvGycLjpRLAHaEq%26pid%3DApi&f=1',
    },
    cigna: {
      viewValue: 'Cigna',
      logo: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.joVPffnuyPhXvGycLjpRLAHaEq%26pid%3DApi&f=1',
    },
    uhc: {
      viewValue: 'United',
      logo: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.joVPffnuyPhXvGycLjpRLAHaEq%26pid%3DApi&f=1',
    },
    none: {
      viewValue: 'None',
      logo: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.joVPffnuyPhXvGycLjpRLAHaEq%26pid%3DApi&f=1',
    },
  };

  months = {
    0: { value: 0, viewValue: 'January' },
    1: { value: 1, viewValue: 'February' },
    2: { value: 2, viewValue: 'March' },
    3: { value: 3, viewValue: 'April' },
    4: { value: 4, viewValue: 'May' },
    5: { value: 5, viewValue: 'June' },
    6: { value: 6, viewValue: 'July' },
    7: { value: 7, viewValue: 'August' },
    8: { value: 8, viewValue: 'September' },
    9: { value: 9, viewValue: 'October' },
    10: { value: 10, viewValue: 'November' },
    11: { value: 11, viewValue: 'December' },
  };

  // devices = {
  //   'withings': {viewValue: 'Withings'},
  //   'bodytrace': {viewValue: 'Body Trace'},
  //   'smartmeter': {viewValue: 'SmartMeter' },
  //   'apple': {viewValue: 'Apple'},
  //   'healthtree': {viewValue: 'HealthTree'},
  //   'telli': {viewValue: 'TelliHealth'},
  // };

  emr = {
    allscripts: { viewValue: 'Allscripts' },
    athena: { viewValue: 'Athena Health' },
    cerner: { viewValue: 'Cerner' },
    epic: { viewValue: 'Epic' },
    fusion: { viewValue: 'Practice Fusion' },
    greenway: { viewValue: 'Greenway Health' },
    kareo: { viewValue: 'Kareo' },
    nextgen: { viewValue: 'NextGen' },
    none: { viewValue: 'None' },
    other: { viewValue: 'Other' },
  };

  // manufacturers = {
  //   withings: 'Withings',
  //   bodytrace: 'Body Trace',
  //   smartmeter: 'SmartMeter',
  //   apple: 'Apple',
  //   healthtree: 'HealthTree',
  //   telli: "TelliHealth"
  // };

  // models = {
  //   scale: 'Scale',
  //   cuff: 'Blood Pressure Cuff',
  //   pulseox: 'Pulse Oximeter',
  //   glucometer: 'Glucometer',
  // };

  measureTypes = {
    'Blood Pressure': {
      id: 'pressure',
      name: 'Blood Pressure',
      value: 'sbp',
      viewValue: 'BP (mmHg)',
      tip: 'Patient triggered a blood pressure alert either exceeding a diastolic trigger or going under a systolic value',
      guidance:
        'Normal BP: <120/80 mmHg.	Elevated: Systolic ranging from 120 to 129 and diastolic <80 mm Hg. Stage 1 hypertension: SBP ranging from 130 to 139 mmHg or DBP ranging from 80 to 89 mm Hg.	Stage 2 hypertension: SBP >140 mmHg or DBP >90 mmHg.	Hypertensive crisis: BP >180/120 mm Hg is an emergency situation that requires urgent medical care.',
    },

    'Blood Glucose': {
      id: 'glucose',
      name: 'Blood Glucose',
      value: 'mgdl',
      viewValue: 'Glucose (mgDl)',
      tip: 'Patient trigger a blood glucose reading.',
      guidance: 'TEST GLU',
    },
    Weight: {
      id: 'weight',
      name: 'Weight',
      value: 'lbs',
      viewValue: 'Wgt (lbs)',
      tip: 'Patient trigger a weight measurement.',
      guidance: 'TEST WT',
    },
    Pulse: {
      id: 'pulse',
      name: 'Pulse',
      value: 'spo2',
      viewValue: 'Rate (bpm)',
      tip: 'Patient triggered a pulse measurement.',
      guidance: 'There is no specific clinical guidance on pulse, but validate the activity levels for the given trigger value.',
    },
  };

  states = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MT',
    'ME',
    'NH',
    'NV',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ];

  genderTypes: string[] = ['male', 'female', 'other'];

  documentTypes = ['Lab Report', 'Monthly Summary', 'Operaative Report', 'Patient Image', 'Other'];

  // private users = {};

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset]).pipe(
    map((result) => result.matches),
    shareReplay()
  );

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  changeSearchTerms(term: string) {
    this.patientSearchSource.next(term);
  }

  setPatientID(id: string) {
    this.messageSource.next(id);
  }

  setTabID(id: number) {
    this.tabSource.next(id);
  }

  getUserName(id: string, printConsole: boolean = false) {
    const user = this.dataModel.allClientUsers[id];
    const name = user ? `${user.firstName} ${user.lastName}` : 'External User';
    //printConsole ? console.log('user', user) : null;
    return name;
  }

  getFirstName(id: string) {
    const user = this.dataModel.allClientUsers[id];
    const name = user ? `${user.firstName}` : 'External User';
    return name;
  }

  getLastName(id: string) {
    const user = this.dataModel.allClientUsers[id];
    const name = user ? `${user.lastName}` : 'External User';
    return name;
  }

  // getResponsibleClientAccountIds(uid: string) {

  //   this.cu
  //   const user = this.dataModel.allClientUsers[uid];
  //   console.log(this.dataModel.allClientUsers);

  //   const client_account_ids = user ? `${user.client_accounts}` : ['N/A'];
  //   return client_account_ids;
  // }

  getUserDOB(id: string) {
    const user = this.dataModel.allClientUsers[id];
    const name = user ? user.dob : 'N/A';
    return name;
  }

  getResponsibleClient(id: string) {
    const user = this.dataModel.allClientUsers[id];
    const name = user ? user.client_responsible : 'N/A';
    return name;
  }

  getClientName(id: string) {
    const client = this.clients[id];
    const name = client ? `${client.client_name}` : id;
    return name;
  }

  private getClients() {
    this.db
      .collection('clients')
      .valueChanges({ idField: 'client_id' })
      .subscribe((clients) => {
        for (const client of clients) {
          this.clients[client.client_id] = client;
        }
      });
  }

  getContactData(key: string) {
    const phoneType = this.phoneTypes[key];
    const type = phoneType ? `${phoneType.icon}` : key;
    return phoneType;
  }

  getAlertShortValue(key: string) {
    const alertType = this.measureTypes[key];
    const type = alertType ? `${alertType.viewValue}` : key;
    return type;
  }

  getAlertTip(key: string, measure_type: string) {
    const alertType = this.measureTypes[key];
    const type = alertType ? `${alertType[measure_type]}` : key;
    return type;
  }

  getDxData(key: string) {
    const dx = this.diagnoses[key];
    // const type = phoneType ? `${phoneType.icon}` : key;
    return dx;
  }

  getPlanData(key: string) {
    const plan = this.plans[key];
    // const type = phoneType ? `${phoneType.icon}` : key;
    return plan;
  }

  getEMR(key: string) {
    const emr = this.emr[key].viewValue;
    return emr;
  }

  getMonth(key: number) {
    const month = this.months[key];
    const monthName = month ? `${month.viewValue}` : 'N/A';
    return monthName;
  }

  getTypeData(type: Type[], fliterValue: string) {
    const filteredType = type.filter(function (t) {
      return t.value === fliterValue;
    });
    return filteredType[0].viewValue;
  }

  // openNewPatientDialog(header: string, body: string): void {

  //   const dialogRef = this.dialog.open(NewPatientDialogComponent, {
  //     width: '300px',
  //     data: { head: header, content: body}
  //   });
  // }

  // converts a web JPG url to a Base64 Image
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = url;
    });
  }

  // generatePass() {
  //   const chars = '0123456789abcdefghijklmnopqrstuvwxyz-ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  //   let pass = '';

  //   for (let i = 0; i < 32; i++) {
  //     pass += chars[Math.floor(Math.random() * chars.length)];
  //   }
  //   return pass;
  // }
}
