import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DocumoService {
  constructor(private http: HttpClient) {}

  sendFileFax(sendFileObject) {
    const body = sendFileObject;
    const url = `${environment.welbyApiBaseUrl}/api/v1/documo/send/fax`;

    return this.http.post(url, body);
  }
}
