export const environment = {
  production: false,

  welbyEndpoint: 'https://dev.api.getwelby.com',

  firebase: {
    apiKey: "AIzaSyB_-q7PWiuMzwGblfEQUSo6Z1yNJCYwu6s",
    authDomain: "welby-dev.firebaseapp.com",
    databaseURL: "https://welby-dev.firebaseio.com",
    projectId: "welby-dev",
    storageBucket: "welby-dev.appspot.com",
    messagingSenderId: "844292793724",
    appId: "1:844292793724:web:34bf55e6e53d88d37fb0f9",
    measurementId: "G-4G9RFWS0CB",
  },

  stripeKey: 'pk_test_YZ8AWD4QSbzy4AZIW9P64hhV00ScSrAald',

  firebaseURL: 'https://us-central1-welby-dev.cloudfunctions.net',
  storageBucket: 'welby-dev.appspot.com',

  pandaApi: '2f1cc803070f974f99ded18b0e3208d4689de3f1',
  welbyApiBaseUrl: 'https://dev.api.getwelby.com',
  welbyBaseUrl: 'https://dev.welby.care'
};
