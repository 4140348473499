import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[phoneMask]',
})
export class PhoneFormaterDirective {
  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    let trimmedValue = input.value.replace(/\s+/g, '');

    if (trimmedValue.length > 12) {
      trimmedValue = trimmedValue.substring(0, 12);
    }

    trimmedValue = trimmedValue.replace(/-/g, '');

    let numbers = [];

    numbers.push(trimmedValue.substring(0, 3));
    if (trimmedValue.substring(3, 6) !== '') numbers.push(trimmedValue.substring(3, 6));
    if (trimmedValue.substring(6, 10) != '') numbers.push(trimmedValue.substring(6, 10));

    input.value = numbers.join('-').substring(0, 12);
  }
}
