import { firestore } from 'firebase';
import { CarePlanItem } from 'src/app/models/carereplan-model';
import { Medication } from './../models/fhir-models';
import { ClinicalTime } from './../models/clinical.model';
import { EmailMessage } from './../models/email-message.model';
import { ClinicalTimeDialogComponent } from './../dialogs/clinical-time-dialog.component';
import { InsuranceCoverage, PatientDiagnosis, PatientAddress } from './../models/patient.model';
import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { ClinicalTask } from '../models/clinical-task.model';
import { Note } from '../models/note.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Patient, Message, PatientContact, Device, PatientAppointment } from '../models/patient.model';
import { Observable } from 'rxjs';
import { GoogleCalendar } from 'datebook';
import { FirestoreService } from './firestore.service';
import { ClinicalResolution } from '../models/clinical.model';
import { PatientVO } from './device-ordering/value-objects/order.vo';
import { NewAuthService } from './auth/new-auth-service.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { filter, map, take, tap } from 'rxjs/operators';
import { TimeTrackingModalComponent } from '../shared/time-tracking-modal/time-tracking-modal.component';
import { TimeTrackingState } from '../shared/time-tracking-modal/time-tracking-states';
import { Router } from '@angular/router';
import { SnackService } from './snack.service';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  currentPatientService: Patient;
  currentPatientServiceID: string;
  patientCell: string;
  patient$: Observable<Patient>;
  insurances$: Observable<InsuranceCoverage[]>;
  clinicalAssessments$: Observable<ClinicalResolution[]>;
  countingTime = false;
  completedTime = false;
  timeSpent = 0;
  minutesSpent = 0;
  secondsSpent = 0;
  interval;
  messages: Message[];
  requestMessage: EmailMessage;
  continueConfirmed = false; //confirming to continue long periods
  creatingNote = false;
  ten_minutes_in_seconds: number = 600;
  one_minute_in_seconds: number = 60;
  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);

  constructor(
    private db: AngularFirestore,
    private auth: NewAuthService,
    public dialog: MatDialog,
    private fsService: FirestoreService,
    private router: Router,
    private snackBar: SnackService,
    private readonly breakpointObserver: BreakpointObserver
  ) {}

  getPatientVO(): PatientVO {
    return {
      id: this.currentPatientService.user_id,
      firstName: this.currentPatientService.firstName,
      lastName: this.currentPatientService.lastName,
      email: this.currentPatientService.email,
      address: {
        street: this.currentPatientService.addresses ? this.currentPatientService.addresses[0]?.address ?? '' : '',
        city: this.currentPatientService.addresses ? this.currentPatientService.addresses[0]?.city ?? '' : '',
        state: this.currentPatientService.addresses ? this.currentPatientService.addresses[0]?.state ?? '' : '',
        postalCode: this.currentPatientService.addresses ? this.currentPatientService.addresses[0]?.zip ?? '' : '',
        country: 'US',
        phone: '18889193529',
      },
    };
  }

  // sets the current patient that you are working on so you always have their ID available.
  setCurrentPatient(id: string) {
    this.patient$ = this.fsService.doc$(`users/${id}`);
    this.patient$.subscribe((patient: Patient) => {
      this.currentPatientService = patient;
      this.currentPatientServiceID = patient.user_id;
      this.fsService.colWithIds$(`users/${id}/my_contacts`).subscribe((contacts: PatientContact[]) => {
        this.currentPatientService.contacts = contacts;
      });
      this.fsService.colWithIds$(`users/${id}/my_health_coverage`).subscribe((coverage: InsuranceCoverage[]) => {
        this.currentPatientService.coverages = coverage;
      });
      this.fsService.colWithIds$(`users/${id}/my_diagnoses`).subscribe((diagnoses: PatientDiagnosis[]) => {
        this.currentPatientService.diagnoses = diagnoses;
      });
      this.fsService.colWithIds$(`users/${id}/my_addresses`).subscribe((addresses: PatientAddress[]) => {
        this.currentPatientService.addresses = addresses;
      });
      this.insurances$ = this.fsService.colWithIds$(`users/${id}/my_health_coverage`);
      this.clinicalAssessments$ = this.fsService.colWithIds$(`clinical_assessments`, (ref) => ref.where('associations.uid', '==', id));
      return patient;
    });
  }

  getPatientCell() {
    const userListRef = this.db
      .collection('users')
      .doc(this.currentPatientServiceID)
      .collection('my_contacts', (ref) => ref.where('contact_type', '==', 'cell'));
    userListRef.valueChanges().subscribe((contactRecord: PatientContact[]) => {
      this.patientCell = contactRecord[0].contact_data;
      return contactRecord[0].contact_data;
    });
  }

  getPatientMessages(clientID: string) {
    const messageRef = this.db.collection('messages', (ref) => ref.where('client_id', '==', clientID));
    messageRef.valueChanges().subscribe((messages: Message[]) => {
      // console.log('hmmm', messages);
      this.messages = messages;
      // console.log('hmmm2', messages);
      return messages;
    });
  }

  getPatientDevices(id: string): any {
    let deviceList = [];
    const deviceRef = this.db.collection('users').doc(id).collection('my_devices');
    deviceRef.valueChanges().subscribe((devices: Device[]) => {
      console.log('service', devices);
      deviceList = devices;
    });
    // return ('poop')
    return deviceList;
  }

  contactPatient(contactType: string, contactData: string) {
    console.log('contacting patient', contactType);
    if (contactType === 'cellular' || contactType === 'home') {
      window.open('tel:+' + contactData);
    } else {
      window.location.href = 'mailto:' + contactData;
    }
  }

  toggleTime() {
    this.countingTime = !this.countingTime;
    clearInterval(this.interval);

    // const secs = this.timeSpent%60
    let newTime = {
      category: 'rpms',
      minutes: 0,
      seconds: 0,
      timeStamp: new Date(),
      user_id: this.currentPatientServiceID,
      excluded: false,
    };

    if (!this.countingTime) {
      // if button clicked while not counting time, open the dialog
      // this.timeSpent = this.timeSpent;
      newTime.minutes =
        this.timeSpent > this.one_minute_in_seconds
          ? (this.timeSpent - (this.timeSpent % this.one_minute_in_seconds)) / this.one_minute_in_seconds
          : 0;
      newTime.seconds = this.timeSpent % this.one_minute_in_seconds;
      this.openClinicalTimeDialog(newTime, this.currentPatientServiceID, true, true);
    } else {
      this.interval = setInterval(() => {
        this.timeSpent++;
        this.minutesSpent = Math.floor(this.timeSpent / this.one_minute_in_seconds);
        this.secondsSpent = this.timeSpent - this.minutesSpent * this.one_minute_in_seconds;
        if ((this.timeSpent - 1) % this.ten_minutes_in_seconds == 0 && this.timeSpent > 1 && this.countingTime == true) {
          //every 10 minutes, loop this logic
          this.countingTime = false;
          clearInterval(this.interval);
          const modal = this.openTimeTrackingModal();
          this.setTimeTrackingAfterCloseListener(modal)
            .pipe(filter((discardFlag) => !!discardFlag))
            .subscribe(() => this.router.navigateByUrl('clinical'));
          this.isExtraSmall.subscribe((size) => {
            if (size.matches) {
              modal.updateSize('100vw', '100vh');
            }
          });
        }
      }, 1000);
    }
  }

  openClinicalTimeDialog(time?: ClinicalTime, id?: string, liveLoad?: boolean, isNew?: boolean): void {
    const dialogRef = this.dialog.open(ClinicalTimeDialogComponent, {
      width: '400px',
      maxWidth: '100vw',
      data: time
        ? {
            time: {
              minutes: time.minutes,
              mins: time.minutes,
              secs: time['seconds'],
              category: time.category,
              timeStamp: time.timeStamp,
              user_id: time.user_id,
              patient_id: time.patient_id,
              excluded: time.excluded,
              contact: time.contact,
            },
            isNew: isNew,
            liveLoad: '' ? false : true,
          }
        : { time: {}, isNew: isNew },
    });

    this.isExtraSmall.subscribe((size) => {
      if (size.matches) {
        dialogRef.updateSize('100vw', '100vh');
      }
    });
  }

  resetInterval(): void {
    clearInterval(this.interval);
    this.completedTime = true;
    this.timeSpent = 0;
    this.minutesSpent = 0;
    this.secondsSpent = 0;
  }

  handleClinicalTime(time: ClinicalTime, patientID: string, isNew: boolean, timeID?: string, isLive?: boolean): void {
    if (isNew) {
      this.db.collection('clinical_time').add(time);
      this.db
        .collection('users')
        .doc(patientID)
        .collection('clinical_time')
        .add(time)
        .then(() => this.snackBar.genericSnackBar('Time saved', ['success-snackbar']));
    } else {
      this.db.collection('clinical_time').doc(timeID).set(time);
      this.db
        .collection('users')
        .doc(patientID)
        .collection('clinical_time')
        .doc(timeID)
        .set(time)
        .then(() => this.snackBar.genericSnackBar('Time saved', ['success-snackbar']));
    }
  }

  async addDevice(device: Device, patientID: string, isNew: boolean) {
    // const deviceMap = `${device.device_manufacturer}_${device.device_model}`;
    // console.log('mapping?', deviceMap);
    // const patientName = await this.db.collection('users').doc(patientID).ref.get().then((doc) => {
    //       if(doc.exists) {
    //         console.log();
    //         const name = `${doc.data()['firstName']} ${doc.data()['lastName']}`
    //         return name;
    //       } else {
    //         return patientID;
    //       }
    //     }).catch(err => {
    //       console.log('something went wrong '+ err)
    //       return null;
    //     });
    // const clientName = await this.db.collection('clients').doc(this.auth.user.client_responsible_id).ref.get().then((doc) => {
    //   if(doc.exists) {
    //     const clientName = doc.data()['client_name']!! ? doc.data()['client_name'] : this.auth.user.client_responsible_id
    //     return clientName;
    //   } else {
    //     return `Not listed. ID: ${this.auth.user.client_responsible_id}`;
    //   }
    // }).catch(err => {
    //   console.log('something went wrong '+ err)
    //   return null;
    // });
    // this.requestMessage = {
    //   to: ['seth@getwelby.com'],
    //   message: {
    //     subject: 'New Device Request',
    //     text: `New request for ${patientName}. ${clientName} has requested a ${device.device_manufacturer} ${device.device_model} to be ordered`,
    //     html: `New request for ${patientName}. ${clientName} has requested a ${device.device_manufacturer} ${device.device_model} to be ordered`,
    //   }}
    // if (isNew) {
    //   this.db.collection('device_requests').add(this.requestMessage)
    //   return this.db.collection('users').doc(patientID).collection('my_devices').doc(deviceMap).set(device);
    // } else {
    //   return this.db.collection('users').doc(patientID).collection('my_devices').doc(deviceMap).set(device, { merge: true });
    // }
  }

  addMessage(message: Message) {
    return this.db.collection('messages').add(message);
    // return this.db.collection('users').doc(patientID).collection('messages').add(message);
  }

  toggleNoteView() {
    this.creatingNote = !this.creatingNote;
  }

  addClinicalNote(note: Note, taskID: string) {
    return this.db.collection('user_notes').add(note);
  }

  addClinicalResolution(resolution: ClinicalResolution, isNew: boolean, withTask?: boolean, task?: ClinicalTask, id?: string) {
    // first just add to the patient record for latest notes
    console.log('pinging', resolution);

    this.db.collection('users').doc(resolution.associations.uid).set(
      {
        last_assessment: resolution.date,
        next_follow_up: resolution.follow_up,
      },
      { merge: true }
    );

    // If we are adding a task as well, it will open the dialog
    if (isNew && withTask) {
      console.log('resolution with a task', resolution, resolution.associations.uid, task);
      this.addClinicalTask(task, resolution);
      // this.openAddTaskDialog(resolution.associations.uid, resolution);
    } else if (isNew) {
      return this.db.collection('clinical_assessments').add(resolution);
      // return this.db.collection('clients').doc(this.auth.user.client_responsible_id).collection('clinical_resolutions')
      // .add(resolution);
    } else {
      return this.db.collection('clinical_assessments').doc(id).set(resolution, { merge: true });
      // return this.db.collection('clients').doc(this.auth.user.client_responsible_id)
      // .collection('clinical_resolutions').doc(id).set(resolution, {merge: true});
    }
  }

  async addClinicalTask(task: ClinicalTask, resolution?: ClinicalResolution) {
    return this.db
      .collection('clinical_tasks')
      .add(task)
      .then((ref) => {
        resolution.associations.task_id = ref.id;
        this.db.collection('clinical_assessments').add(resolution);
        // this.db.collection('clients').doc(this.auth.user.client_responsible_id).collection('clinical_resolutions').add(resolution);
      })
      .catch((err) => {
        console.log('Error loading the resolution', err);
      });
  }

  async createPatientAppointment(appointment: any, url: string, type: string, apptTime: any) {
    const currentUser = this.auth.user.user_id;
    const currentClient = this.auth.user.client_responsible_id;
    const newAppt: PatientAppointment = {
      creator_id: currentUser,
      patient_id: this.currentPatientServiceID,
      date: apptTime,
      type: 'video',
      status: { isActive: false, isWaiting: false },
    };

    await this.db
      .collection('clients')
      .doc(currentClient)
      .collection('appointments')
      .add(newAppt)
      .then((doc) => {
        this.db.collection('users').doc(this.currentPatientServiceID).collection('appointments').doc(doc.id).set(newAppt);
        this.db
          .collection('users')
          .doc(currentUser)
          .collection('appointments')
          .doc(doc.id)
          .set(newAppt)
          .then(() => {
            switch (type) {
              case 'ics':
                console.log('mac');
                appointment.download();
                break;
              default:
                const gCal = appointment as GoogleCalendar;
                console.log('none given', gCal.render());
                window.open(url, '_blank');
                break;
            }
          })
          .catch((err) => {
            console.log('Error loading appointment', err);
          });
      })
      .catch((err) => {
        console.log('Error loading appointment', err);
      });
  }

  handlePatientAddress(isNew: boolean, address: PatientAddress, patientID?: string, addressID?: string) {
    if (isNew) {
      return this.db.collection('users').doc(patientID).collection('my_addresses').add(address);
    } else {
      console.log('loading add', address, addressID);
      return this.db.collection('users').doc(patientID).collection('my_addresses').doc(addressID).set(address, { merge: true });
    }
  }

  handlePatientDx(isNew: boolean, dx: PatientDiagnosis, patientID?: string, dxId?: string) {
    if (isNew) {
      return this.db.collection('users').doc(patientID).collection('my_fhir_diagnoses').add(dx);
    } else {
      return this.db.collection('users').doc(patientID).collection('my_fhir_diagnoses').doc(dxId).set(dx, { merge: true });
    }
  }

  handlePatientRx(isNew: boolean, rx: Medication, patientID?: string, rxId?: string) {
    console.log('test', isNew, rx);
    if (isNew) {
      return this.db.collection('users').doc(patientID).collection('my_fhir_medications').add(rx);
    } else {
      return this.db.collection('users').doc(patientID).collection('my_fhir_medications').doc(rxId).set(rx, { merge: true });
    }
  }

  async handlePatientCarePlan(isNew: boolean, type: string, item: CarePlanItem, patientID: string, originalItem?: CarePlanItem) {
    this.db.collection('care_plans').doc(patientID).set(
      {
        last_update: new Date(),
        uid: patientID,
      },
      { merge: true }
    );

    if (isNew) {
      return this.db.collection('care_plans').doc(patientID).collection(type).add(item);
    } else {
      console.log(item, originalItem);
    }
  }

  /**
   * CaseManagement
   * @param planId
   * @param newItem
   * @param originaItem
   * @returns
   */
  async editCaseItem(planId: string, newItem: CarePlanItem, originaItem: CarePlanItem) {
    const ref = this.db.collection('cases').doc(planId);
    await ref.update({
      items: firestore.FieldValue.arrayRemove(originaItem),
    });
    ref.update({
      items: firestore.FieldValue.arrayUnion(newItem),
    });
    return;
  }

  openTimeTrackingModal(): MatDialogRef<TimeTrackingModalComponent> {
    const modal = this.dialog.open(TimeTrackingModalComponent, {
      maxWidth: '100vw',
      data: {
        title: `Confirm that you are still tracking patient time`,
        body: 'Are you still tracking patient time?',
      },
    });
    this.isExtraSmall.subscribe((size) => {
      if (size.matches) {
        modal.updateSize('100vw', '100vh');
      }
    });
    return modal;
  }

  setTimeTrackingAfterCloseListener(modal: MatDialogRef<TimeTrackingModalComponent>): Observable<boolean> {
    return modal.afterClosed().pipe(
      take(1),
      map((resp) => {
        switch (resp) {
          case TimeTrackingState.KEEP_TRACKING:
            this.countingTime = false;
            this.toggleTime();
            break;

          case (TimeTrackingState.SAVE_TIME, TimeTrackingState.NOACTION):
            break;

          default:
            return true;
        }
        return false;
      })
    );
  }
}
