import { SnackService } from './../../../services/snack.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { Guid } from 'guid-typescript';
import { InitialDataModel } from './../../../services/models/initial-data-model.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-patient-demographics-dialog',
  templateUrl: './patient-demographics-dialog.component.html',
  styleUrls: ['./patient-demographics-dialog.component.scss']
})
export class PatientDemographicsDialogComponent implements OnInit {

  // THIS IS THE NEW MINIMIZED PATIENT LOADING FORM.
  patientForm: FormGroup;
  serverMessage: '';
  owners = [];
  isLoading = false;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PatientDemographicsDialogComponent>,
    private dataService: DataService,
    public dataModel: InitialDataModel,
    public ds: DataService,
    private auth: NewAuthService,
    private http: HttpClient,
    private afAuth: AngularFireAuth,
    private snack: SnackService,
  ) { }

  ngOnInit(): void {

    this.patientForm = this.fb.group({
      client_id: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      dob: ['', [Validators.required, this.ageValidator]],
      email: ['', [Validators.email]],
      provider_id: ['', [Validators.required]],
      emr_id: ['', []],
      ssn_id: ['', [Validators.minLength(4), Validators.maxLength(4)]],
      notes: ['', []],
      rpm: ['', []],
      alert_sms: ['', [Validators.minLength(10), Validators.pattern('[0-9]*')]],
    });

    this.owners = Object.values(this.dataModel.clientProviderPanel$);
    this.owners = this.owners.filter(u => u.roles.isClient);
  }


  ageValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const currentDate = new Date();
    if (control.value !== null && (isNaN(control.value) || control.value > currentDate)) {
      return { 'ageValidator': false }
    }
    return null;
  };




  loadPatientToDatabase() {

    this.isLoading = true;
    const uid = Guid.create().toString();
    const userEmail = this.patientForm.value['email']!! ? this.patientForm.value['email'] : `${uid}@gelby.com`;
    const firstName = this.patientForm.value.firstName;
    const lastName = this.patientForm.value.lastName;

    console.log(firstName);


    const patient = {
      user_account_access: [this.auth.user.user_id],
      client_accounts: [this.auth.user.client_responsible_id],
      alert_contacts: [{type: 'provider', data:'+18584493348'}],
      client_responsible: this.dataModel.clients[this.patientForm.value.client_id].client_name,
      client_responsible_id: this.patientForm.value.client_id,
      provider_id: this.patientForm.value.provider_id,
      dob: new Date(this.patientForm.value.dob),

      email: userEmail,
      emr_id: this.patientForm.value.emr_id,
      firstName: firstName,
      gender: 'other',
      height_inches: 50,
      lastName: lastName,

      notes: this.patientForm.value.notes,
      pregnancies: 0,
      // roles: {isAdmin: false, isClient: false, isPatient: true, isPracticeAdmin: false},
      search_terms: [
        firstName.toLowerCase(),
        lastName.toLowerCase(),
        userEmail.toLowerCase(),
        this.dataService.getFirstName(this.patientForm.value.provider_id,).toLowerCase(),
        this.dataService.getLastName(this.patientForm.value.provider_id,).toLowerCase(),
      ],
      ssn_id: this.patientForm.value.ssn_id,
      weight_lbs: 100,
      providerGenerated: true,
    }

    // console.log('form', this.patientForm);

    // console.log('pat', patient);


    this.http.post(`${environment.firebaseURL}/backendUser/createUser`, patient, { responseType: 'text'}
    ).subscribe(
      (val) => {
          this.afAuth.auth.sendPasswordResetEmail(userEmail);
          this.isLoading = false;
          this.snack.patientLoaded(val);
          console.log('POST call successful value returned in body', val);
          this.dialogRef.close()
      },
      response => {
          console.log('POST really screwed something up', response);
          return('bear poo');
      },
      () => {
          console.log('The POST observable is now completed.');
          return('poo bear');
      });
  }



  onNoClick(): void {
    this.dialogRef.close();
  }



}
