<form [formGroup]="patientForm">

  <h1 mat-dialog-title>Load A New Patient</h1>
  <div mat-dialog-content>

    <mat-form-field style="width: 49%; margin-right: 2%;" *ngIf="dataModel.clients$ | async as panel" >
      <mat-label>Client</mat-label>
      <mat-select formControlName='client_id' [(ngModel)]="data.client_id" required>
        <mat-option *ngFor="let client of panel" [value]="client.client_id">
          {{ client.client_name }}
        </mat-option>
      </mat-select>
      <mat-error>You must assign a client</mat-error>
    </mat-form-field>

    <mat-form-field style="width: 49%;" *ngIf="dataModel.clientProviderPanel$ | async as panel">
      <mat-label>Primary Provider</mat-label>
      <mat-select formControlName='provider_id' [(ngModel)]="data.provider_id" required>
        <mat-option *ngFor="let user of panel" [value]="user.user_id">
          {{ user.lastName }}, {{ user.firstName }}
        </mat-option>
      </mat-select>
      <mat-error>You must assign a primary provider</mat-error>
    </mat-form-field>

    <mat-form-field style="width:49%; margin-right:2%;">
      <input placeholder="First Name" matInput formControlName="firstName" [(ngModel)]="data.firstName" required>
      <mat-error>First Name is required</mat-error>
    </mat-form-field>

    <mat-form-field style="width:49%;">
      <input placeholder="Last Name" matInput formControlName="lastName" [(ngModel)]="data.lastName" required>
      <mat-error>Last Name is required</mat-error>
    </mat-form-field>

    <mat-form-field style="width:100%;">
      <mat-label>Patient Email</mat-label>
      <input placeholder="Email is required for patient login" matInput formControlName="email" [(ngModel)]="data.email" />
      <!-- <mat-hint>Leave blank if no email account</mat-hint> -->
    </mat-form-field>

    <mat-form-field style="width:32%; margin-right: 2%;">
      <mat-label>MRN</mat-label>
      <input placeholder="Patient EMR ID" matInput formControlName="emr_id" [(ngModel)]="data.emr_id" />
    </mat-form-field>

    <mat-form-field style="width:32%; margin-right: 2%;">
      <input placeholder="Last 4 Social" matInput formControlName="ssn_id" [(ngModel)]="data.ssn_id" />
    </mat-form-field>

    <mat-form-field style="width:32%">
      <input matInput formControlName="dob" [(ngModel)]="data.dob" [matDatepicker]="picker" placeholder="Date of Birth" required>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error>Valid DOB required</mat-error>
    </mat-form-field>

    <mat-form-field style="width:100%;">
      <input placeholder="Notes" matInput formControlName="notes" [(ngModel)]="data.notes" />
    </mat-form-field>

  </div>
  <div mat-dialog-actions style="margin-bottom: 5px;" align="end">

    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="primary" (click)="loadPatientToDatabase()" [disabled]="patientForm.invalid || isLoading" cdkFocusInitial>
      Load Patient
    </button>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading" style="padding-top: 10px;"></mat-progress-bar>
</form>



